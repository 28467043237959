.com-h{
    min-height: 88vh;
    overflow-y: scroll;
}
.com-h::-webkit-scrollbar{
    display:none;
}
.table-div{
    height: 68vh;
}
.dates-div{
    width: 90%;
    margin-left: 2px;
    background-color:  rgb(251, 251, 251);
    font-size: 14px;
    font-family: "mukta";
    font-weight: 600;
    color: white;
}
.from{
    background-color: rgb(230 230 230);
    height:35px;
    color: #363333 !important;
    width:40px;
    /* border-radius: 3px; */

}
.dates-div input{
    background-color: rgb(230 230 230);
    height: 35px;
    width: 9rem;
    /* border-radius: 3px; */
    border: none;
    color: #363333 !important;
    border-color: lightgray;

}

@media screen and (max-width: 700px){
    .table-div{
        height: 55vh;
    }
    

}



