:root {
  --primary-color: #000000;
  /* Blue */
  --color-white: white;
  --color-white1: #d1d1d4;
  --color-black: black;
  --color-black1: #2c3e50;
  --color-yellow1: #ffc500;
}

@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400&display=swap");

:root {
  --text-primary: #08c;
  --text-secondary: #2d3e50;
  --primary-bg: #fff;
  --secondary-bg: #000;
  --link-bg: #bbb;
  --grey-bg: #ccc;
  --light-bg: #eee;
  --suspended-color: #730709;
  --red-color: #ff0000;
  --yellow-color: #fab700;
  --green-color: #00ff00;
  --white-color: #fff;
  --site-color: #000;
  --green-bg: #145b12;
  --blue-color: #0000ff;
}

nav {
  border-bottom: 1px solid #fd740d !important;
  background-color: black;
}

.white-space-nowrap {
  white-space: nowrap;
}

.navbar {
  height: 11vh;
}

.navbar-brand {
  height: 55px;
}

@media only screen and (max-width: 750px) {
  .signin-btn {
    /* padding: 6px 20px; */
    height: 25px !important;
    width: 90% !important;
    border-radius: 6px;
    border: none !important;
    color: white;
    font-size: 16px;
    background: linear-gradient(to right, #1368de 0, #4a8ff0 15%, #548ee0 30%, #1567d8 55%, #2869c3 100%) !important;
  }

  .wallet-btn {
    height: 28px !important;
    width: 115px !important;
    border-radius: 6px;
    background: linear-gradient(to right, #fa5004 0%, #fd740d 51%, #fa5004 100%);
    color: white;
    border: none;
  }

  .schedule-btn {
    background-color: #5a5858;
    height: 47px !important;
    text-align: center;
    width: 94px !important;
    color: white;
    font-size: 11px;
    border-radius: 5px;
  }

  .in-play-btn {
    background-color: #030303;
    height: unset !important;
    width: 112px !important;
    color: #fd7e14;
    border: 1px solid #fd7e14;
    font-size: 10px;
    border-radius: 5px;
  }
}

.signin-btn {
  /* padding: 6px 20px; */
  height: 36px;
  width: 90px;
  border-radius: 6px;
  border: none !important;
  color: white;
  font-size: 16px;
  background: linear-gradient(to right, #1368de 0, #4a8ff0 15%, #548ee0 30%, #1567d8 55%, #2869c3 100%) !important;
}

.wallet-btn {
  height: 36px;
  width: 140px;
  border-radius: 6px;
  background: linear-gradient(to right, #fa5004 0%, #fd740d 51%, #fa5004 100%);
  color: white;
  border: none;
}

.nav-btn {
  /* background-color: rgb(82, 81, 81); */
  background-color: #333333;
  padding: 6px 20px;
  border-radius: 6px;
  border: none !important;
  color: white;
  font-size: 13px;
  margin-left: 4px;
  font-family: "mukta";
  font-weight: 600;
  text-decoration: none;
}

.nav-btn:hover {
  background: linear-gradient(to right, #fa5004 0%, #fd740d 51%, #fa5004 100%);
}

.nav-btn.is-active {
  background: linear-gradient(to right, #fa5004 0%, #fd740d 51%, #fa5004 100%);
}

/* Dropdown Button */
.dropbtn {
  background-color: transparent;
  color: white;
  padding: 10px;
  font-size: 1.7rem;
  border: none;
  cursor: pointer;
}

MenuItem {
  padding-top: 0;
  padding-bottom: 0;
}

.dropdown-nav a {
  text-decoration: none;
  color: #2e2e2e
}

.dropdown-nav a div {
  font-size: .92rem;
  padding: 6px 12px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  padding-top: 0;
  padding-bottom: 0;
}

.dropbtn:hover,
.dropbtn:focus {}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 30%;
  background-color: #43CEED;
  min-width: 170px;
  /* height: 70px; */
  font-size: 16px;
  font-weight: 600px;
  /* height: 20px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 9px 20px;
  text-decoration: none;
  font-size: 12px;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #1ea9c9;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  /* background-color: #2980b9; */
}

.person-icon-div {
  background-color: #fa5004;
  border-radius: 50%;
  color: black;
  height: 2.8rem;
  width: 2.8rem;
}

.p-icon {
  /* font-size: 15px; */
}

/************ Updated NavBar section  ************/
.cont-head {
  background-color: var(--primary-color);
}

.main-header {
  height: 5rem;
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .2rem .1rem .2rem .7rem;
  margin-top: .7rem;
  margin-bottom: .3rem;
}

.left-section-header {
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-header-img {
  height: 6rem;
  width: 6rem;
  margin-left: .5rem;
}

.right-section-header {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.main-header-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.bal-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-white);
}

@media only screen and (max-width: 750px) {
  .bal-container div h5 {
    font-size: 14px;
    fontFamily: "Roboto Condensed", "sans-serif";
  }

  .balances h5 {
    width: 25vw;
  }

  .balances h4 {
    width: 25vw;
    fontFamily: "Roboto Condensed", "sans-serif";
    font-size: 14px;
  }
}

.bal-container div h5 {
  font-size: .8rem;
  margin: 0;
  fontFamily: "Roboto Condensed", "sans-serif";
}

.balances h5 {
  font-size: .8rem;
}

.balances h4 {
  font-size: .8rem;
  text-decoration: underline;
  fontFamily: "Roboto Condensed", "sans-serif";
}

.marque-div {
  width: 70%;
  margin-left: 30%;
}

.marque {
  position: relative;
  width: 100%;
  margin-left: rem;
  color: white;
  font-size: 12px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 12px;
  position: relative;
  margin-top: -1.5rem;
  padding-bottom: .5rem;
}

.game-option {
  display: flex;
  align-items: center;
  height: 2rem;
  width: 100%;
  /* background-color: var(--primary-color); */
  color: var(--primary-bg);
}

.game-option div {
  /* margin: auto .4rem; */
  height: 100%;
  font-weight: 700;
  padding: .1rem .7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.game-option div:first-child {
  background-color: #223577;
}

.game-option div:last-child {
  background-color: #bb1919;
}

.nav-link {
  cursor: default;
}

.nav-link:hover,
.nav-link:active {
  border-bottom: 2px solid var(--color-white);
}

.navLink {
  text-decoration: none;
  color: var(--color-white-);
}

.navLinkPop {
  animation: mymove 1s infinite;
}

@keyframes mymove {
  50% {
    font-size: 1.2rem;
  }
}

/* Media Queries for responsiveness */
@media screen and (max-width: 750px) {
  .main-header {
    height: auto;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
  }

  .left-section-header {
    flex-direction: column;
    width: 50%;
    align-items: start;
  }

  .main-header-img {
    /* margin-bottom: 1rem; */
  }

  .right-section-header {
    width: 20%;
  }

  .marque-div {
    width: 90%;
    margin: 0 2% 0 8%;
    margin-top: -2rem;
  }

  .marque {
    padding-left: 13%;
    margin-top: -1rem;
  }

  .main-header-right {
    width: 100%;
  }

  .bal-container {
    width: 80%;
    flex-direction: column;
    justify-content: center;
  }

  .rules {
    display: none;
  }

  .game-option {
    /* flex-direction: column; */
    /* flex-wrap: wrap; */
    /* height: 5rem; */
    width: auto;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .game-option div {
    width: auto;
    height: 2rem;
    /* margin: 1rem auto; */
  }

  .navLink {
    width: auto;
    display: flex;
    justify-content: space-between;
    word-wrap: none;
  }

  .nav-link {
    width: auto;
    display: flex;
    justify-content: space-between;
  }

  .navLinkPop {
    animation: normal;
  }
}

/**** Bottom header  **********/
.header-bottom {
  background-color: var(--color-yellow1);
  overflow-x: scroll;
  /* margin-top: 10px; */
  margin: 0;
  /* scrollbar-width: none; */
  /* -ms-overflow-style: none; */
  /* padding: 9px 0; */
}
/* .header-bottom::-webkit-scrollbar {
  display: none;
} */

.header-bottom li a {
  color: var(--secondary-color);
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 .3rem;
  font-size: .9rem;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.header-bottom nav {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  height: 3.5rem;
  background-color: var(--color-yellow1);
  color: #000;
  fontFamily: "Roboto Condensed", "sans-serif";
}

.header-bottom nav div ul {
  flex: flex;
  align-items: center;
  justify-content: center;
}

.header-bottom li a:hover {
  text-decoration: none;
  background-color: transparent !important;
  color: inherit !important;
}

.header-bottom li a:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  right: 50%;
  bottom: 0;
  background: var(--primary-bg);
  height: 2px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.header-bottom li a:hover:before,
.header-bottom li a:focus:before,
.header-bottom li a:active:before {
  left: 0;
  right: 0;
}

.header-bottom li a.active {
  background-color: var(--primary-bg);
  color: var(--secondary-color);
}

.new-launch-text {
  animation: new-launch 1s linear infinite;
}

@keyframes new-launch {
  50% {
    opacity: 0;
  }
}

@media screen and (max-width: 750px) {
  .header-bottom {
    display: none;
  }

  .main-header {
    height: auto;
    /* flex-direction: column; */
    align-items: center;
    margin: 0;
    justify-content: space-between;
  }

  .left-section-header {
    flex-direction: column;
    width: 50%;
  }

  .main-header-img {
    /* margin-bottom: 1rem; */
    margin-left: -.5rem;
  }

  .right-section-header {
    width: 20%;
  }

  .marque-div {
    width: 90%;
    margin: 0 2% 0 8%;
    margin-top: -2rem;
  }

  .marque {
    padding-left: 13%;
    margin-top: -1rem;
  }

  .main-header-right {
    width: 100%;
  }

  .bal-container {
    width: 80%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .rules {
    display: none;
  }

  .game-option {
    /* flex-direction: column; */
    /* flex-wrap: wrap; */
    /* height: 5rem; */
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .game-option div {
    width: auto;
    height: 2rem;
    width: 50%;
    /* margin: 1rem auto; */
  }

  .navLink {
    width: auto;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 16px;
    font-family: "Roboto Condensed", sans-serif;
    word-wrap: none;
  }

  .nav-link {
    width: auto;
    display: flex;
    justify-content: space-between;
  }

  .navLinkPop {
    animation: normal;
  }
}

/**************  OPTIONAL NAVBAR   *****************/
.InPlay-section {
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  height: 2rem;
  width: 100%;
  padding: 0;
  background-color: #000
}

@media screen and (min-width: 900px) {
  .popular-nav {
    display: none;
  }

  .InPlay-section {
    display: none;
  }
}

.InPlay-section div {
  font-weight: 700;
  height: 100%;
  padding: .1rem .7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  border-right: 2px solid white;
}

.InPlay-section div::after {
  content: "";
  background: rgb(255, 255, 255);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 2px;
  font-size: 12px;
}

.InPlay-section div:last-child {
  border-right: none;
}

/********************* Dropdown Content *****************/
.dropdown-content-profile {
  width: 100%;
}

.dropdown-content-Addaccount {
  width: 95%;
  overflow-x: hidden;
}

.dropdown-content-profit {
  width: 95%;
}

.dropdown-content-Withdraw {
  width: 96%;
}

.dropdown-content-withdrawH {
  width: 99%;
}

.dropdown-content-transactionH {
  width: 94%;
}
.dropdown-content-funds{
  width:98%
}
@media screen and (min-width: 900px) {
  .dropdown-content-profile {
    width: 79%;
    margin-right: 3rem;
  }

  .dropdown-content-Addaccount {
    width: 80%;
    margin-right: 2.5rem;
  }

  .dropdown-content-profit {
    width: 79%;
    margin-right: 2.5rem;
  }

  .dropdown-content-Withdraw {
    width: 79%;
    margin-right: 3rem;
  }

  .dropdown-content-withdrawH {
    width: 79%;
    margin-right: 2.7rem;
  }

  .dropdown-content-transactionH {
    width: 79%;
    margin-right: 2.7rem;
  }
  .dropdown-content-funds{
    width: 79%;
    margin-right: 3rem;
  }
}