@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400&display=swap");
:root {
  --text-primary: #08c;
  --text-secondary: #2d3e50;
  --primary-bg: #fff;
  --secondary-bg: #000;
  --link-bg: #bbb;
  --grey-bg: #ccc;
  --light-bg: #eee;
  --suspended-color: #730709;
  --red-color: #ff0000;
  --yellow-color: #ffff00;
  --green-color: #00ff00;
  --white-color: #fff;
  --site-color: #000;
  --green-bg: #145b12;
  --blue-color: #0000ff;
}
.sb-h{
    border-bottom: 1px solid #43CEED;
    font-size: 14px;
    color:#43CEED;
    font-family: "mukta";
    font-weight: 600;
  }
  .side-bar{
    background-color: #202020;
    height : 88vh;
    overflow-y: scroll;
    
  }
  .side-bar::-webkit-scrollbar{
    display:none;
}
.sm-side-bar{
 
  top:11%;
  z-index: 999999;

}
  .list-group{
    background-color: #202020;
  
  }
  .list-group-item{
    background-color: #202020;
    border: none !important;
    border-bottom: 1px solid grey !important;
    color: white !important;
    font-size: 14px;
    font-family: "mukta";
    font-weight: 600;
    height: 45px;
  
  }
  .list-group-item:hover{
    /* translate: 3px 0px 0px; */
    transition: .5s ease;
    border-left: 2px solid #fa5004 !important;
  }
  .side-icon{
    height: 20px;
    color: white !important;
    /* background-color: white; */
    border: none !important;
    opacity: 1;
  }
  .menu{
    background-color: #3b3838;
    color: white;
    font-family: "mukta";
    font-weight: 500;
    border-top: 1px solid grey;
    min-height :39px;
    max-height: auto;
    font-size: 15px;
    transition: .5s ease;
    
  }
  .subMenu{
    height: 32px;
    font-size: 15px;
    font-weight: 600;
    font-family: "mukta";
    border-top: 1px solid grey;
    background-color: #333030;

  }
  .menu-icon{
    color:white;
    font-size: 19px;
    /* transition: all .2s ease-out; */
  }
  /* <---------------------------------->sdebardropdown>>>>>>>>>>>>>>>>>>>> */
  .dropdown-btn {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    border: none;
    background: none;
    width:100%;
    text-align: left;
    cursor: pointer;
    outline: none;
  }
 

/* <-------------------------------------------------------------------------------------------------->>>>>> */


  
  
  @media screen and (max-width: 550px){
    .side-bar{
        width: 200px;
        
        /* position: absolute; */
        /* z-index: 9999; */
        /* top: 40; */
        /* bottom: 30; */
    }


}


/********** Optional New Side Bar ****************/

.sidebar {
  padding: 0 !important;
  /* min-height: calc(100vh - 159px); */
  height: 100%;
  width: 100% !important;
  background-color: var(--grey-bg);
  color: var(--site-color);
}
.accordion-body{
  padding: 0;
  background-color: var(--grey-bg);

}
.Accordion.Header{
  background-color: var(--text-primary);
}
.accordion-button:not(.collapsed), .accordion-button {
  background-color: #000000 !important;
  color: white !important;
}
.custom-header {
  background-color: rgb(255, 255, 255); /* Change to your desired background color */
  color: white; /* Change to your desired text color */
}

.sidebar-title {
  background-color: var(--theme1-bg);
  background-color: var(--text-primary);
  padding-bottom: 0;
  cursor: pointer;
  padding: 4px 6px;
  margin-bottom: 0;
  margin-top: 1px;
}

.sidebar-title h5 {
  color: var(--primary-color);
  width: 100%;
  font-size: 18px;
}

.sidebar .sidebar-title :before {
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  padding-right: 3px;
  font-weight: 900;
  float: right;
}

.sidebar .sidebar-title.collapsed :before {
  content: "\f105";
}

.sidebar .nav-item .nav-link {
  background: var(--link-bg);
  color: var(--site-color);
  border-bottom: 1px solid #9e9e9e;
  font-weight: normal;
  font-size: 14px;
  padding: 0.3rem 1rem;
}

.sidebar .nav-item.active .nav-link {
  background-color: rgba(0, 0, 0, 0.2);
  color: var(--white-color);
}

.sidebar .mtree-main {
  padding: 5px 10px;
}

.sidebar .mtree li {
  padding: 2px 0 2px 10px;
  position: relative;
}

.sidebar .mtree > li {
  padding-left: 0;
}

.sidebar .mtree li a {
  position: relative;
  font-size: 12px;
  text-decoration: none;
  color: var(--site-color);
}

.sidebar .mtree li a:hover {
  text-decoration: none;
}

  