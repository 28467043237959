
.coupon-card {
    /* margin-bottom: 16px; */
    border-radius: 0 0 2px 0px;
  }
  .coupon-card .card-header {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    font-size: 12px;
    padding: 8px;
    width: 100%;
    float: left;
    color: var(--primary-color);
    background-color: var(--theme1-bg);
  }
  .coupon-card-first .card-header {
    border-radius: 0;
  }
  .card-header {
    /*line-height: 1.6;*/
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: var(--theme2-bg);
    color: var(--secondary-color);
  }
  .card-header .card-header-title-container {
    float: left;
    line-height: 26px;
  }
  .coupon-table {
    margin-bottom: 0;
  }
  .coupon-table thead th {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    padding: 6px 0;
    padding-left: 0px;
    color: #303030;
  }
  .coupon-table thead th:first-child {
    text-align: left;
    width: auto;
    padding-left: 15px;
    text-indent: 0;
    padding-right: 15px;
  }
  .coupon-table .main-market thead th:first-child {
    width: 52%;
  }
  .coupon-table .bookmaker-market thead th:first-child {
    width: 68%;
  }
  .bookmaker-container .box-w1 {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
  }
  .coupon-table td:first-child .active {
    margin-right: 30px;
    float: right;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #28a745;
  }
.coupon-table tr td{
    padding: 0;
    vertical-align: middle;
    font-size: 14px;
    word-wrap: break-word;
  }
  .coupon-table td{
    padding:.3rem 0;
  }
.game-name {
    width:76%;
    float: left;
    margin-left: 1rem;
  }
  @media only screen and (max-width: 900px) {
    .tab-content{
      display: none;
    }
    .game-name{width: 100%;
    margin-left: 0;
    }
    .game-icons {
      padding-top:5px
    }
}
  .game-name a{
    text-decoration: none;
    color:#343a40!important;
    font-family: "Roboto Condensed", sans-serif;
    /* font-size: 14px; */
  }
  .game-name a:hover{
    text-decoration: underline;
  }
  .game-icons {
    float: left;
    min-width: 30%;
    text-align: right;
    margin-right: .5rem;
  }
  @media only screen and (min-width: 900px) {

  .x12{
    display: none;
  }
}
  .game-icon {
    width: 25px;
    text-align: center;
    margin-top: 2px;
    display: inline-block;
  }
  .game-icon .active {
    display: inline-block !important;
    float: none !important;
    vertical-align: middle !important;
    margin-right: 0 !important;
  }
  .game-name p:first-child{
    font-weight: bold;
    color: black;
    font-size: 13px;
  } 
  .coupon-table td:first-child .active {
    background-color: #28a745;
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
  .coupon-table button {
    width: 100%;
    min-width: 40px;
    height: 25px;
    margin: 0;
    text-align: center;
    display: inline-block;
    padding: 0px;
    color: #273a47;
    border: 0;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
.icon-tv {
    font-size: 0.6875rem;
  }
  .bookmaker-icon,
  .fancy-icon {
    height: 12px;
  }
  .ic-card {
    height: 18px;
  }
.lay,
.lay-color {
  background-color: #faa9ba !important;
}
.back,
.back-color {
  background-color: #72bbef !important;
}
.layx {
  background-color: #faa9ba !important;
}
.backx {
  background-color: #72bbef !important;
}