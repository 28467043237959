.bet-h{
    /* background-color: aqua; */
    height: auto;
    width: 100%;
    background-color: #ffffff;
    color: white;
    border-radius: 5px;;
    -left: .7rem;
    border: 1px solid black;
}
.bet-h h4{
    color: white;
    font-weight: 700;
    font-family: "mukta";
    background-color: var(--color-black1);
    
    font-size: 20px;
    background-color: var(--color-black1);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.bd{
    border: 1px solid #666;
    width: 100%;
    font-size: 12px;
    font-weight: 700;
    font-family: "mukta";
    
}
.record{
    font-weight: 600;
    font-family: "mukta";
}