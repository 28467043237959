
    :root {
        --primary-color: #000000;
        /* Blue */
        --color-white: white;
        --color-white1: #d1d1d4;
        --color-black: black;
        --color-black1: #2c3e50;
        --color-yellow1:#ffc500;
    --color-black1: #2c3e50;
    --font-family-main:"Roboto Condensed", sans-serif;
}
.score-card {
    background-color: #ffffff;
    height: auto;
    border-radius: 5px;
    overflow-y: scroll;
}
@media screen and (min-width: 900px) {
    .score-card {
        width: 67%;
        left: 0;
    }

}
.score-card::-webkit-scrollbar {
    display: none;
}
.match-name-div {
    min-width: 250px;
    width: auto;
    /* background-color: aqua; */
    height: 60px;
    max-width: auto;
    /* max-width: auto; */
}
.match-name-btn {
    padding: 10px 10px 10px 10px;
    /* height: 34px; */
    color: #333;
    /* width: 90%; */
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    /* line-height: initial; */
    display: inline-block;
    /* position: absolute; */
    margin-top: 15px;
    /* top: 5px; */
    /* left: 200px; */
    background-color: #ff8a0c;
    align-items: center;
    /* display: flex; */
    /* justify-content: center; */
    border-radius: 25px;
    /* z-index: 1; */
    -webkit-animation: puls 3s linear infinite;
    animation: puls 3s linear infinite;
    background: linear-gradient(to right, #f76904 0, #ff8c30 15%, #ff9b30 30%, #f78b04 55%, #ff8a0c 100%);
    color: #FFF;
    /* text-shadow: 1px 1px 3px rgb(0 0 0 / 50%); */
    word-spacing: 3px;
    border: none !important;
}
hr {
    background: linear-gradient(to right, #f76904 0, #ff8c30 15%, #ff9b30 30%, #f78b04 55%, #ff8a0c 100%);
    color: linear-gradient(to right, #f76904 0, #ff8c30 15%, #ff9b30 30%, #f78b04 55%, #ff8a0c 100%);
    height: 2px;
    border: none !important;
    margin-bottom: 100px;
    /* border-top:1.5 px solid #ff8a0c; */
    /* position: relative; */
    /* border-top-left-radius: 5px;
        border-top-right-radius: 5px; */
    /* line-height: 1.5;
        padding-top: 60px; */
    /* display: flex; */
    /* justify-content: center; */
    /* display: block; */
}
.mn {
    color: #f78b04;
    font-family: "mukta";
    font-weight: 500;
    font-size: 17px;
}
.vs {
    color: #ED213A;
}
.in-play-main-div {
    color: white;
}
.blue-btn {
    border: none;
    background: linear-gradient(to right, #1368de 0, #4a8ff0 15%, #548ee0 30%, #1567d8 55%, #2869c3 100%) !important;
}
.main-score-card-div {
    min-height: 150px;
    width: 100%;
}
.batting-icon {
    background-color: #f1e6e6;
    color: white;
    border-radius: 50px;
    width: 52px;
    height: 52px;
}
.bat-icon-img {
    height: 33px;
}
.team-a-name {
    font-size: 27px;
    color: white;
    font-family: "mukta";
    font-weight: 700;
}
.A-team-score {
    font-size: 10px;
    color: white;
}
.bd {
    border: 1px solid white;
}
.stumps {
    width: 100%;
    height: 50px;
    border-radius: 50px;
    font-family: "mukta";
    font-weight: 600;
}
.batter-info {
    color: white;
    font-size: 12px;
}
.over-ball-div {
    height: 60px;
    border-radius: 25px;
    background-color: rgb(224, 217, 217);
    width: 70%;
}
.single-info {
    background-color: lightseagreen;
    border-radius: 20px;
    font-size: 13px;
    width: 27px;
    height: 27px;
    margin: .15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-family: "mukta";
}
.red-info {
    background-color: red;
    border-radius: 20px;
    font-size: 13px;
    width: 27px;
    height: 27px;
    margin: .15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-family: "mukta";
}
.four-info {
    background-color: rgb(98, 158, 7);
    border-radius: 20px;
    font-size: 13px;
    width: 27px;
    height: 27px;
    margin: .15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-family: "mukta";
}
.back-lay-row {
    height: auto;
    padding: 0;
    border-bottom: 1px solid white;
}
.six-info {
    background-color: rgb(253, 228, 2);
    border-radius: 20px;
    font-size: 13px;
    width: 27px;
    height: 27px;
    margin: .15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-family: "mukta";
}
.favourite {
    color: rgb(230, 230, 12);
    font-size: 13px;
    font-weight: 600;
    font-family: "mukta";
}
.no-run-info {
    background-color: rgb(187, 180, 180);
    border-radius: 20px;
    font-size: 13px;
    width: 27px;
    height: 27px;
    margin: .15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-family: "mukta";
}
.betting-info {
    border-bottom: 2px solid #ff8a0c;
}
.betting-info-btn {
    /* background-color: #cfc3c3; */
    border: none;
    height: 37px;
    color: white;
    font-size: 14px;
    font-family: "mukta";
}
.Match-ods-h {
    display: flex;
    justify-content: space-between;
    background-color: var(--color-black1);
    /* background-color: #0fc960; */
    height: 2rem;
    width: 100%;
    font-family: "mukta";
    font-weight: 700;
    /* height: 35px; */
    color: white;
}
.betting-team-info {
    /* margin-top: 20px;
    border-top: 1px solid rgb(146, 137, 137); */
    background-color: #e1dede;
    height: auto;
    font-family: "mukta";
    color:#000
}
.back-lay-row_1 {
    height: 30px;
}
.bgContainer{
    background-color: #e8e7e7;
    margin-left: .2rem;
}
.betting-team-info_1 {
    /* margin-top: 20px; */
    /* border-top: 1px solid rgb(76, 74, 74); */
    background-color: #e3e1e1;
    color:black;
    height: auto;
    font-family: "mukta";
}
.inplay-w {
    font-size: 13px;
    font-family: "mukta";
    font-weight: 500;
}
.lead {
    color: white;
    font-size: 14px;
    font-family: "mukta";
}
.green-live-dot {
    height: 7px;
    width: 7px;
    font-size: 1rem;
    background-color: rgb(12, 101, 12);
    border-radius: 5px;
}
.betting-t-n {
    color: rgb(30, 30, 30);
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-left: 0;
}
.betting-t-n:first-child{
    font-family: "Roboto Condensed", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 1;
}
.left-betting p:last-child{
    margin-bottom: 0;
    padding-top: 4px;
}
.back {
    background-color: #089CF1;
    border: none;
    color: white;
    font-family: "mukta";
    border-radius: 3px;
    width: 65px
}
.lay {
    background-color: #d63384;
    border-radius: 3px;
    border: none;
    color: white;
    font-family: "mukta";
    width: 65px;
}
.zero {
    color: light greenyellow !important;
}
.blue-bet-btn {
    background-color:  #72bbef !important;
    border: none;
    color: rgb(0, 0, 0);
    font-family: "mukta";
    /* border-radius: 3px; */
    width: 4.5rem;
    height: 2.7rem;
}
.orange-bet-btn div span , .blue-bet-btn div span{
    font-size: .7rem;
    cursor: pointer;
}
.orange-bet-btn,.blue-bet-btn div{
    font-size: 1.1rem;
    font-weight: 600;
}
.orange-bet-btn {
    background-color:#faa9ba !important;
    /* border-radius: 3px; */
    border: none;
    color: rgb(0, 0, 0);
    font-family: "mukta";
    width: 4.5rem;
    height: 2.7rem;
}
.TV-outer-div {
    background-color: #cac7c7;
    border:1px solid grey;
    height: 27rem;
    border-radius: 5px;
}
.live-tv-card {
    background-color: var(--color-yellow1);
    height: 15.5rem;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 1rem;
    margin-left: 5px;
}
.live-tv1 {
    background-color: #292828;
    /* height: 300px; */
    border-radius: 5px;
    margin-bottom: 1rem;
}
.live-tv-card-active {
    background-color: #292828;
    /* height: 300px; */
    border-radius: 5px;
    ;
}
.tv-card {
    border-bottom: 1px solid #43CEED;
}
.tv-card p {
    color: #43CEED;
    font-size: 14px;
}
.tv-icon {
    color: #ff8a0c !important;
    font-size: 22px;
}
.ifram-div{
    width:90.9%;
    height:95%;
    /* margineft: 5px */
}
.matched-bets {
    position: absolute;
    left: 73%;
    top: 20%;
    height: 12rem;
    /* background-color: #464343; */
    width:24.5%;
    border-radius: 5px;
}
.bet-matches {
    color: #ff8a0c !important;
    font-weight: 600;
    font-family: "mukta";
}
.no-bets {
    color: white;
    font-weight: 600;
    font-size: 13px;
    border-top: 1px solid white;
}
@media screen and (max-width: 900px) {
    .matched-bets {
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .batting-icon {
        background-color: #f1e6e6;
        color: white;
        border-radius: 50px;
        width: 30px;
        height: 28px;
    }
    .bat-icon-img {
        height: 20px;
    }
    .team-a-name {
        font-size: 13px;
        color: white;
        font-family: "mukta";
        font-weight: 500;
    }
    
}
.sec__bg {
    height: fit-content;
}
.tr_data_1 {
    position: absolute;
    background: #000000d1;
    padding: 12px 29px 12px 30px;
    color: red;
    text-align: center;
    height: 100%;
    font-size: 18px;
}
.pos_re {
    position: relative;
}
.btnn {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    border-radius: 7px;
    padding: 5px 10px;
    margin: 5px;
    border: 2px solid #fff;
    box-shadow: 3px 2px;
    font-style: italic;
    animation: all ease 1s;
}
.btnn-dark {
    background-color: #000;
}
.btnn-red {
    background-color: #f10707;
}
.btnn-green {
    background-color: #2ebb34;
}
.btnn-green:active,
.btnn-red:active {
    scale: 0.9;
}
/* .aside-bet-section{
    position: relative;
    right:0;
    margin-left: 83vw;
    width:10%
} */
/**************** Score Card Section   ********************/
.betting-cricket-container {
    width: 100%;
    /* background-color: aqua; */
    display: flex;
    margin: .1rem 0 0 .4rem;
    color: var(--color-white);
    height: auto;
    font-family: var(--font-family-main);
}
.container-left {
    width: 70%;
}
.container-left-div1 {
    width: 100%;
    margin: 1rem 0;
}
.left-container-heading {
    display: flex;
    justify-content: space-between;
    background-color: var(--color-black1);
    height: 2rem;
    width: 101%;
}
.left-container-heading h5 {
    font-size: .8rem;
    font-weight: 660;
    margin: auto .2rem;
    line-height: 1;
    font-family: var(--font-family-main);
}
.left-container-total-run {
    background-image: url("https://diamonddexch789.com/assets/images/scorecard-bg.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: .5rem;
    width:101%
}
.left-container-total-run-left h5 {
    font-size: 14px;
    font-family: var(--font-family-main);
}
.left-container-total-run-left {
    padding-top: .5rem;
    padding-bottom: .5rem;
    width: 40%;
}
.left-container-total-run-left div {
    display: flex;
    justify-content: space-between;
}
.run-rate {
    display: flex;
    justify-content: space-between;
    width: 60%;
}
.left-container-total-run-balls {
    display: flex;
    justify-content: space-evenly;
}
.left-container-total-run-balls p {
    background-color: var(--primary-color);
    border-radius: 50%;
    width: 1.7rem;
    height: 1.7rem;
    margin: .2rem;
    font-size: 1.3rem;
    font-weight: 650;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fixed-ifram-div{
    position:fixed;
    top:0%;
    left:0%;
    padding: auto;
    width:101vw;
    height:101vh;
    background-color:black;
    z-index: 101;
}
.ifram-div{
    width:98%;
    height:102%;
    /* marginLeft: 5px */
}
.ifram-div-full{
    position:fixed;
    top:0%;
    /* left: 13%; */
    /* width:{Math.min(165vh || 100vw)}; */
    height: 102vh;
    z-index: 100;
    text-align: center;
    overflow:hidden;
    background-color: black;
}
.zoom-btn{
    position: relative;
    margin-left: 91%;
    /* margin-top: -8%; */
    z-index: 101;
    /* position:absolute;
    left:70%;
    top:10%; */
}
.zoom-btn-full{
    position: relative;
    margin-left: 95%;
    margin-top: -8%;
    z-index: 101;
}
@media screen and (min-width: 750px) {
    .betting-tv-body{
        height:100vh;
    }
    .live-tv-card{
        height: 29rem;
        width: 101%;
    }
    .zoom-btn-full{
        position: relative;
        margin-left: 97%;
        margin-top: -5%;
        z-index: 101;
    }
    .zoom-btn{
        position: relative;
        margin-left: 91%;
        margin-top: -8%;
        z-index: 101;
        /* position:absolute;
        left:70%;
        top:10%; */
    }
}