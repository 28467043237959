.home{
    overflow-y: scroll;
    height:88vh;
    width: 100%;
    background-color: white;
    /* background-color: aqua !important; */
}
.sideBar-dis{
    width: 16.5%;
    padding-left: 0.75rem;
}
@media only screen and (min-width: 900px) {
    .home{
        width: 81%;
        margin-right: .8rem;
        height: auto;
    }

}
.home::-webkit-scrollbar{
    display:none;
}