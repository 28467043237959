.withdrawal-main-div{
    background-color: rgb(255, 255, 255);
    min-height: 86vh;
    width: 100%;
    max-height: auto;
    border-radius: 5px;
    /* border:1px solid rgb(157, 156, 156) */

}
.F-h{
    font-weight: 700;
    color: white ;
    font-size: 20px;
    background-color: var(--color-black1);
    padding-left: .7rem;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    
}
.form-area{
    /* border-top: 1px solid rgb(66, 61, 61); */
}
.w-card{
    height: 350px;
    width: 370px;
    background-color: black;
    border: 1px solid rgb(99, 96, 96);
    color: white;
}
.balance-div{
    
}
.balance-h{
    color: white;
    font-size: 14px;
}
.b-o{
    color:#fa5004;
    font-size: 17px;
    font-weight: 600;
    font-family: "mukta";
}
label{
    font-size: 11px;

}
.user-id-input{
    background-color:rgb(102, 146, 146);
}

.transfer-btn{
    width: 100%;
    background-color: #fa5004;
    color: white;
}
@media screen and (max-width: 490px){
    .w-card{
        height: 420px;
        width: 340px;
        background-color: black;
        border: 1px solid rgb(99, 96, 96);
        color: white;
    }
    .balance-h{
        font-size: 12px;
    }


}