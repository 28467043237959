.backgrounds {
    background-color: black;
    /* background-size:auto; */
    /* margin-left: .5rem; */
    position: fixed;
    top: 0;
    width: 103%;
    height: 100%;
    background-size: 100% 100%;
    /* background-repeat: no-repeat; */
    /* position: relative; */
    z-index: 99;
    overflow: hidden;
}
.logo-img{
    height:7rem;
    width: 100%rem;
    margin-top: 6rem;
}
.img-divs {
    /* margin-bottom: .5rem; */
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.3;
}


.login-card {
    margin-top: 1rem;
    height:27%;
    width: 21%;
    background-color: #fff;
    /* position: absolute; */
    z-index: 99999;
    top: 20%;
}
@media only screen and (max-height: 850px){
    .login-card {
        margin-top: 5.5rem;
        height:fit-content;
        width: 21%;
        background-color: #fff;
        /* position: absolute; */
        z-index: 99999;
        /* top: 20%; */
    } 
}
@media only screen and (min-width: 850px){
    .login-card {
        margin-top: 1rem;
    }
}
.login-card form {
    color: white;
    text-align: center;
}
.login-input {
    /* display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; */
    /* outline: none !important;  */
    /* border: none !important; */
    background-color: rgb(255, 255, 255);
    height: 40px;
    width: 100%;
    border: 2px solid #5d6164;
    background-clip: padding-box;
    border-radius: 5px;
    border: 1px solid #ced4da;
    color: rgb(61, 53, 53) !important;
    display: flex;
    justify-content: center;
}
.login-input input {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 14px;
    line-height: 2;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border:none;
    border-bottom: 2.5px solid #cf9f1c;
    /* border-radius: .25rem; */
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    /* outline: none !important;  */
    /* border: none !important; */
}
.login-input input:focus{
    border: 2px solid rgba(159, 159, 250, 0.556);
    box-shadow: rgba(112, 112, 244, 0.55);
    border-color: rgba(143, 171, 248, 0.576);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(70, 90, 242, 0.621);
  outline: 0 none;
}
.login-input div{
    margin-left: -2rem;
    margin-top: .5rem;
}
.login-btn {
    width: 100%;
    background-color: #000000;
    color: white;
    font-family: "mukta";
}
.login-btn:hover {
    /* background-color: #fa5004; */
}
.bth {
    /* color: white; */
    font-size: 10px;
    color:#000000;
    font-family: Roboto Condensed, sans-serif;
    line-height: 15px;
    margin-top: .2rem;
}
.bth span {
    /* color: #fa5004; */
}