@media screen and (max-width: 900px) {
}
.game-list {
  display: flex;
  height: 1.5rem;
  font-weight: 550;
  color: black;
  border-bottom: 1px solid var(--color-white1);
}
.game-name p {
  font-size: .9rem;
  cursor: pointer;
  text-decoration: none;
  color: #343a40 !important;
  font-family: "Roboto Condensed", sans-serif;
     font-size: 14px;
     margin-bottom: 0;
}
.game-name p:first-child{
  font-weight: bold;
  color: black;
  font-size: 13px;
}
.game-name img {
  width: 100%;
  height: .8rem;
  margin-top: .3rem;
}
.dot-active{
  display: flex;
  background-color: #28a745;
    height: 12px;
    width: 12px;
    border-radius: 50%;
}
.result-list {
  background-color: azure;
  width: 40%;
  display: flex;
}
@media screen and (min-width: 900px) {
  .game-list{
    height: 2.4rem;
  }
  .game-name p:first-child{
    font-weight: 500;
    font-size: 14px;
  }
  .result-list{
    padding-top:.4rem;
    padding-bottom: .4rem;
  }
}
.result-list div {
  text-align: center;
  width: 18%;
  border-top: 1px solid var(--color-white1);
  border-bottom: 1px solid var(--color-white1);
  font-family: "Roboto Condensed", sans-serif;
    font-size: 14px;
    font-weight: bold;
}
.result-list div:nth-child(2n+1) {
  background-color: #72bbef !important; 
  border-left: 1px solid var(--color-white1);
}
.result-list div:nth-child(2n) {
  background-color: rgb(250, 169, 186);
  border-right: 1px solid var(--color-white1);
}
@media only screen and (max-width: 900px) {
  .body-section {
    flex-direction: column;
  }
  .sideBar-dis{
    display: none;
  }
  .aside-container {
    width: 100%;
    margin-top: 0.3rem;
    margin-left: 0;
  }
  .betting-section-container {
    width: 100%;
    margin: 0;
  }
  .betting-cricket-container {
    margin: 0;
  }
  .upper-games {
    overflow-x: scroll;
  }
  .upper-games div {
    border-right: none;
  }
  .game-heading {
    flex-direction: column;
  }
  .game-heading-div1 {
    width: 100%;
    font-weight: 660;
    padding-left: 0.5rem;
  }
  .betting-field {
    width: 100%;
  }
  .game-list {
    width: 95%;
    flex-direction: column;
    height: auto;
    color: black;
    padding-left: 0rem;
    border-bottom: 1px solid var(--color-white1);
    margin-left: 1.7rem;
  }
  .game-name{
    width: 100%;
  }
  .game-name {
    width: 100%;
    border: none;
    padding-left: 0.3rem;
  }
  .game-name p {
    display: flex;
    flex-direction: column;
    text-decoration: none;
  font-family: "Roboto Condensed", sans-serif;
     font-size: 14px;
     margin-bottom: 0;
  }
  .game-name p span:nth-child(2) {
    font-size: .8rem;
    font-weight: 450;
  color: #343a40 ;
  }
  .game-name img {
    width: 100%;
  }
  .game-icon{
    width: 20%;
  }
  .result-list {
    width: 100%;
  }
  .result-list div {
    width: 100%;
  }
  .aside-container {
    display: none;
  }
}
.game-name {
  width: 60%;
  padding-left: 1rem;
  /* border: 1px solid var(--color-white1); */
  border-left: none;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
