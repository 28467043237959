body {
    background-color: rgb(255, 255, 255);
    color: white;
    font-family: Arial, sans-serif;
}
select {
    /* padding: ; */
    font-size: 16px;
    height: 35px;
    border: none;
    background-color: rgb(237, 235, 235);
    color: rgb(90, 88, 88);
}
option {
    background-color: black;
    color: white;
    /* padding-top: 0; */
}