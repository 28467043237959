.search-box{
    width: fit-content;
    height: fit-content;
    position: relative;
    z-index: 2;
  }
  .input-search{
    margin: 0 0 4px 4px;
    height: 2.2rem;
    width: 1.2rem;
    border-style: none;
    padding: 2px;
    font-size: 18px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
    transition: all .5s ease-in-out;
    /* background-color: #24585c; */
    background-color: white;
    padding-right: 40px;
    color:#300303;
  }
  .input-search::placeholder{
    color:rgba(0, 0, 0, 0.3);
    font-family: Roboto Condensed, sans-serif;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold;
    padding-left: .1rem;
    
  }
  .btn-search{
    width: 2.1rem;
    height: 2.2rem;
    border-style: none;
    /* font-size: 3; */
    font-weight: bold;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    color:#ffffff ;
    background-color:transparent;
    pointer-events: painted;  
    padding-left: 0;
    padding-top: 2px;
  }
  .btn-search:focus ~ .input-search{
    width: 200px;
    border-radius: 5px;
    background-color: transparent;
    border-bottom:3px solid rgba(255,255,255,.7);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
    background-color: white;
  }
  .input-search:focus{
    width: 200px;
    border-radius: 5px;
    background-color:transparent;
    border-bottom:3px solid rgba(255,255,255,.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
    background-color: white;
  }
  
  