.color-game-div{
    height: 89vh;
    /* width: 50%; */
    overflow-y: scroll;
}
.color-game-div::-webkit-scrollbar{
    display:none;
}
.color-game-h{
    background-color: #2C3D51;
    width: 100%;
    height: 39px;
}
.color-game-h p{
    color: white;
    font-family: "mukta";
    font-size: 18px;
    font-weight: 500;
}
.color-game-card{
    height: 340px;
    width: 100%;
    border-radius: 6px;
    background-color: rgb(206, 206, 221);
}
.Green-button{
    background-image: linear-gradient(to right, #3d8b13 0%, #25c321 51%, #6c7b65 100%);
    box-shadow: rgb(0 194 130 / 40%) 0px 0px 8px;
    margin: 5px;
    padding: 5px 30px;
    text-align: center;
    height: 55px;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 3px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    border: none;
    font-family: "mukta";
    font-weight:bolder;
}
.violet-btn{
    background-image: linear-gradient(to right, #9D50BB 0%, #6E48AA 51%, #9D50BB 100%);
    box-shadow: rgb(155 39 176 / 40%) 0px 0px 8px;
    margin: 0px;
    padding: 0px 27px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    xbox-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
    /* margin-right: auto;
    margin-left: auto; */
    border: none;
    height: 47px;
    font-family: "mukta";
    font-weight:bolder;
}
.violet-button{
    background-image: linear-gradient(to right, #9D50BB 0%, #6E48AA 51%, #9D50BB 100%);
    box-shadow: rgb(155 39 176 / 40%) 0px 0px 8px;
    margin: 5px;
    padding: 5px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    xbox-shadow: 0 0 20px #eee;
    border-radius: 3px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    border: none;
    height: 55px;
    font-family: "mukta";
    font-weight:bolder;
}
.red-button{
    background-image: linear-gradient(to right, #e52d27 0%, #b31217 51%, #e52d27 100%);
    box-shadow: rgb(250 60 9 / 40%) 0px 0px 8px;
    margin: 5px;
    padding: 5px 30px;
    margin-right: 5px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 3px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    height: 55px;
    font-family: "mukta";
    font-weight:bolder;
    border: none;
}
.violet-red-btn{
    background: linear-gradient(to right bottom, rgb(141 77 181) 50%, rgb(227 44 39) 50%);
    border: none;
    height: 47px;
    width: 70px;
    border-radius: 10px;
    color: white;
    font-family: "mukta";
    font-weight: bold;
    font-size: 20px;
}
.small-green-btn{
    background-color: #2FB11D;
    border: none;
    height: 47px;
    width: 70px;
    border-radius: 10px;
    color: white;
    font-family: "mukta";
    font-weight: bold;
    font-size: 20px;
    margin-right: 5px;
}
.small-red-btn{
    background-color: #E32E27;
    border: none;
    height: 47px;
    width: 70px;
    border-radius: 10px;
    color: white;
    font-family: "mukta";
    font-weight: bold;
    font-size: 20px;
        margin-right: 5px;
}
.violet-green-btn{
    background: linear-gradient(to right bottom, rgb(141 77 181) 50%, rgb(47 177 29) 50%);
    border: none;
    height: 47px;
    width: 70px;
    border-radius: 10px;
    color: white;
    font-family: "mukta";
    font-weight: bold;
    font-size: 20px;
}
.table-h-div{
    background-color: #E4E4E4;
    width: 100%;
    height: 37px;
    color: #243A48;
    font: 13px;
    font-family: "mukta";
    font-weight: bold;
}
.table-inner-row{
    background-color: white;
    width: 100%;
    height: 45px;
    color: #243A48;
    font-size: 15px;
    font-family: "mukta";
    font-weight: 500;
    border-bottom: 1px solid #b0b5b8;
}
.red-dot{
    width:20px;
    height: 20px;
    background-color: #E32E27;
    border-radius: 10px;
}
.violet-dot{
    width:20px;
    height: 20px;
    background-color:rgb(141 77 181) ;
    border-radius: 10px;
}
.green-dot{
    width:20px;
    height: 20px;
    background-color:#25c321 ;
    border-radius: 10px;
}
.plus-icon{
    height: 20px;
    width: 25px;
}
.fail{
    color: rgb(235, 44, 44) !important;
}
.success{
    color:#25c321;
}
.bet-menu-card{
    width:100%;
    height:370px;
    color: #243A48;
    font-size: 17px;
    font-family: "mukta";
    font-weight: 500;
}
.bet-menu-card-h{
    color:#0ecf9f;
    font-size: 18px;
    font-family: "mukta";
}
/* <<<<<<<<<<<<<<------------------------------ModalData-------------->>>>>>> */
.color-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: none;
}
.color-modal-container {
    position: absolute;
    width: 520px;
    height:fit-content !important;
    /* height: 310px !important; */
    /* background: linear-gradient(105deg, #7D2DE6 0%, #7E2EE7 40%, #7C2CE5 100%); */
    /* background-color: #01a839; */
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* border-radius: 8px; */
    z-index:999;
}
.model-h-dive{
    background-color: #CFD5D9;
    font-size: 17px;
    /* font-weight: bold; */
    font-weight: 500;
    font-family: "mukta";
    height: 43px;
}
.num-box{
    height: 37px !important;
    width: 80px;
    border: none;
    color: #9e9a9a;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: white;
    /* background-color: rgb(165, 152, 152); */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}
.contract-money{
    font-size: 14px;
    font-family: "mukta";
}
.num-box.is-activated{
    background-color: #b6afaf !important;
    color: rgb(0, 0, 0);
}
.minus-div{
    width: 50px;
    height: 40px !important;
    border: none;
    color: #9e9a9a;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: white;
    font-size: 20px;
}
.plus-div{
    width: 50px;
    height: 40px !important;
    border: none;
    color: #9e9a9a;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: white;
    font-size: 20px;
}
.counter-data-div{
    width: 200px; 
    height: 45px;
    color: black;
    font-size: 20px;
}
.red-modal-btn{
    background-image: linear-gradient(to right, #e52d27 0%, #b31217 51%, #e52d27 100%);
    box-shadow: rgb(250 60 9 / 40%) 0px 0px 8px;
    margin: 5px;
    padding: 5px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 3px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    height: 45px;
    font-family: "mukta";
    font-weight:bolder;
    border: none;
}
.green-modal-btn{
    background-image: linear-gradient(to right, #3d8b13 0%, #25c321 51%, #6c7b65 100%);
    box-shadow: rgb(0 194 130 / 40%) 0px 0px 8px;
    margin: 5px;
    padding: 5px 30px;
    text-align: center;
    height: 45px;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 3px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    border: none;
    font-family: "mukta";
    font-weight:bolder;
}
.alert-s-rem{
    color:#2FB11D;
    font-size: 12px;
    font-family: "mukta";
}
@media screen and (max-width: 500px){
    .color-game-h p{
        font-size: 12px;;
    }
    .color-modal-container {
        position: absolute;
        width: 380px !important;
        height: 300px;
        /* background: linear-gradient(105deg, #7D2DE6 0%, #7E2EE7 40%, #7C2CE5 100%); */
        /* background-color: #01a839; */
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* border-radius: 8px; */
        z-index:999;
    }
}
.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}
@media only screen and (max-width: 600px) {
    .btas {
      background-image: linear-gradient(
        to right,
        #3d8b13 0%,
        #25c321 51%,
        #6c7b65 100%);
      border: none;
      width: 87px !important;
      text-align: center;
      justify-content: center;
      box-shadow: inset 0px 0px 2px 2px #00000063;
      margin-right: 10px;
      padding: 10px 5px;
      border-radius: 10px;
    }
    .dis {
      display: flex;
      justify-content: space-around !important;
      text-align: center;
  }
  }
  .btas {
    background-image: linear-gradient(
      to right,
      #3d8b13 0%,
      #25c321 51%,
      #6c7b65 100%);
    border: none;
    width: 100px;
    margin-right: 10px;
    padding: 10px 5px;
    border-radius: 10px;
  }